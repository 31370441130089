import React from 'react';
import { LocalProductMetadata, LocalProductVariant } from '../../../../../services/ProductServices/LocalProduct';
import {
    Alignment,
    LabelRuleCheckType,
    LabelRuleDisplayType,
    LabelRuleType,
    VerticalAlignment,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { useTranslation } from 'react-i18next';
import { ProductVariantDiscount } from '../../../../../provider/cloudshelf/common/ProductSearchResultWithCursor';

export type CloseButtonCallback = () => void;

export interface ProductLabelProps {
    discounts?: ProductVariantDiscount[];
    variant?: LocalProductVariant;
    metadata?: LocalProductMetadata[];
    tags?: string[];
    useLocation?: boolean;
}

export const ProductLabels: FunctionalComponentWithChildren<ProductLabelProps> = props => {
    const { t } = useTranslation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const labelRules = configService.config()?.theme.labelRules ?? [];
    const location:
        | {
              vertical: VerticalAlignment;
              horizontal: Alignment;
          }
        | undefined = props.useLocation
        ? {
              vertical: configService.config()?.theme.labelVerticalAlignment ?? VerticalAlignment.Bottom,
              horizontal: configService.config()?.theme.labelHorizontalAlignment ?? Alignment.Right,
          }
        : undefined;

    const metadata = props.metadata ?? [];
    const tags = props.tags ?? [];

    if (labelRules.length === 0) {
        return null;
    }

    const discountRules = labelRules.filter(r => r.ruleType === LabelRuleType.Discounted);
    const metadataRules = labelRules.filter(r => r.ruleType === LabelRuleType.Metadata);
    const tagRules = labelRules.filter(r => r.ruleType === LabelRuleType.Tag);

    const buildLabel = (name: string, foregroundColour: string, backgroundColour: string, text: string) => {
        if (text.trim() === '') {
            return null;
        }
        return (
            <div
                className={`ProductLabel ${location ? 'ProductLabel__slim' : ''} ProductLabel__${name
                    .toLocaleLowerCase()
                    .replaceAll(' ', '_')}`}
                style={{ backgroundColor: backgroundColour, color: foregroundColour }}
            >
                {text}
            </div>
        );
    };

    const content = () => {
        return (
            <>
                {props.variant &&
                    props.variant.price !== props.variant.originalPrice &&
                    discountRules.map((r1, index) => {
                        //we already know its discounted,
                        let discountLabelText = '';
                        if (r1.displayType === LabelRuleDisplayType.Text) {
                            discountLabelText = r1.text;
                        } else if (r1.displayType === LabelRuleDisplayType.CalculatePriceDiscount) {
                            const discount = props.variant!.price - props.variant!.originalPrice;
                            discountLabelText = t('discountLabels.amount', {
                                value: CurrencyService.formatRounded(Math.abs(discount)),
                            });
                        } else if (r1.displayType === LabelRuleDisplayType.CalculatePercentDiscount) {
                            const discountPercent =
                                ((props.variant!.originalPrice - props.variant!.price) / props.variant!.originalPrice) *
                                100;
                            discountLabelText = t('discountLabels.percent', { value: discountPercent.toFixed(0) });
                        }
                        return (
                            <React.Fragment key={`${r1.name}-${index}-variant`}>
                                {buildLabel(r1.name, r1.foregroundColour, r1.backgroundColour, discountLabelText)}
                            </React.Fragment>
                        );
                    })}

                {props.discounts &&
                    props.discounts.length > 0 &&
                    discountRules.map((r1, index) => {
                        let labelText = '';
                        if (r1.displayType === LabelRuleDisplayType.CalculatePriceDiscount) {
                            const valueDiscountsWithoutZero = props.discounts!.filter(d => d.value > 0);

                            if (valueDiscountsWithoutZero.length === 0) {
                                return null;
                            }
                            //Reduce of empty array with no initial value
                            const maxValueDiscount = valueDiscountsWithoutZero.reduce((a, b) =>
                                a.value > b.value ? a : b,
                            );

                            const hasAnyDiscountsLessThanMax = valueDiscountsWithoutZero.some(
                                d => d.value < maxValueDiscount.value,
                            );

                            if (!hasAnyDiscountsLessThanMax) {
                                labelText = t('discountLabels.amount', {
                                    value: CurrencyService.format(maxValueDiscount.value),
                                });
                            } else {
                                labelText = t('discountLabels.uptoAmount', {
                                    value: CurrencyService.format(maxValueDiscount.value),
                                });
                            }
                        } else if (r1.displayType === LabelRuleDisplayType.CalculatePercentDiscount) {
                            const percentDiscountsWithoutZero = props.discounts!.filter(d => d.percentage > 0);

                            if (percentDiscountsWithoutZero.length === 0) {
                                return null;
                            }

                            const maxValueDiscount = percentDiscountsWithoutZero.reduce((a, b) =>
                                a.percentage > b.percentage ? a : b,
                            );

                            const hasAnyDiscountsLessThanMax = percentDiscountsWithoutZero.some(
                                d => d.percentage < maxValueDiscount.percentage,
                            );
                            if (!hasAnyDiscountsLessThanMax) {
                                labelText = t('discountLabels.percent', {
                                    value: maxValueDiscount.percentage,
                                });
                            } else {
                                labelText = t('discountLabels.uptoPercent', {
                                    value: maxValueDiscount.percentage,
                                });
                            }
                        }

                        return (
                            <React.Fragment key={`${r1.name}-${index}-discounts`}>
                                {buildLabel(r1.name, r1.foregroundColour, r1.backgroundColour, labelText)}
                            </React.Fragment>
                        );
                    })}

                {tagRules.map((r2, index) => {
                    let tagLabelText = '';
                    if (r2.checkType === LabelRuleCheckType.Exists && tags.includes(r2.tagValue)) {
                        tagLabelText = r2.text;
                    } else if (r2.checkType === LabelRuleCheckType.NotExists && !tags.includes(r2.tagValue)) {
                        tagLabelText = r2.text;
                    }
                    return (
                        <React.Fragment key={`${r2.name}-${index}-tags`}>
                            {buildLabel(r2.name, r2.foregroundColour, r2.backgroundColour, tagLabelText)}
                        </React.Fragment>
                    );
                })}

                {metadataRules.map((r3, index) => {
                    let metadataLabelText = '';

                    if (r3.checkType === LabelRuleCheckType.Exists && metadata.find(m => m.key === r3.metadataKey)) {
                        metadataLabelText = r3.text;
                    } else if (
                        r3.checkType === LabelRuleCheckType.NotExists &&
                        !metadata.find(m => m.key === r3.metadataKey)
                    ) {
                        metadataLabelText = r3.text;
                    } else if (
                        r3.checkType === LabelRuleCheckType.Equal &&
                        metadata.find(m => m.key === r3.metadataKey && m.data === r3.comparisonValue)
                    ) {
                        metadataLabelText = r3.text;
                    } else if (
                        r3.checkType === LabelRuleCheckType.NotEqual &&
                        metadata.find(m => m.key === r3.metadataKey && m.data !== r3.comparisonValue)
                    ) {
                        metadataLabelText = r3.text;
                    }

                    return (
                        <React.Fragment key={`${r3.name}-${index}-metadata`}>
                            {buildLabel(r3.name, r3.foregroundColour, r3.backgroundColour, metadataLabelText)}
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    return (
        <div
            className={`ProductLabels ${
                location
                    ? `ProductLabels__stack ProductLabels__horizontal__${location.horizontal.toLocaleLowerCase()} ProductLabels__vertical__${location.vertical.toLocaleLowerCase()} ${
                          location &&
                          location.horizontal === Alignment.Center &&
                          location.vertical === VerticalAlignment.Center
                              ? 'ProductLabels__location__center'
                              : ''
                      }`
                    : ''
            }`}
        >
            {content()}
        </div>
    );
};
