import { Observable } from 'react-use/lib/useObservable';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import { Checkout, CheckoutStyle } from '../Checkout.type';
import { CheckoutSupportedPaymentMethod } from '../Checkout.Supported.Payment.Method.enum';
import { PossibleTrackedURL } from '../../TrackedURLService/TrackedURL.type';
import { Basket } from '../../BasketService/Basket.type';
import { BasketService } from '../../BasketService/BasketService';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { StorageService } from '../../StorageService/StorageService';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';

export class BaseCheckoutService {
    readonly basketService: BasketService;
    protected readonly configurationService: ConfigurationService;
    readonly checkoutStyle: CheckoutStyle;
    protected readonly storageService: StorageService;

    private readonly checkoutSubject = new BehaviorSubject<Checkout | undefined>(undefined);
    private _checkout: Checkout = {
        loading: false,
        appliedCouponCodes: [],
        total: 0,
        subTotal: 0,
    };

    constructor(
        private readonly _basketService: BasketService,
        private readonly _configurationService: ConfigurationService,
        private readonly _checkoutStyle: CheckoutStyle,
        private readonly _storageService: StorageService,
    ) {
        this.basketService = _basketService;
        this.configurationService = _configurationService;
        this.checkoutStyle = _checkoutStyle;
        this.storageService = _storageService;
    }

    async handleBasketChange(basket: Basket | undefined): Promise<void> {
        //
    }
    get checkout(): Checkout {
        return this._checkout;
    }

    observeCheckout(): Observable<Checkout | undefined> {
        return this.checkoutSubject.asObservable();
    }

    propagateChanges(inputCheckout?: Checkout) {
        if (inputCheckout) {
            this._checkout = inputCheckout;
        }

        this._checkout = _.cloneDeep(this._checkout);

        this.checkoutSubject.next(this._checkout);
    }

    get supportsCoupons() {
        return false;
    }

    clear() {
        this._checkout = {
            loading: false,
            appliedCouponCodes: [],
            total: 0,
            subTotal: 0,
        };
        this.propagateChanges();
    }

    async setCouponCode(couponCode: string): Promise<boolean> {
        return false;
    }

    async supportedPaymentMethods(): Promise<CheckoutSupportedPaymentMethod[]> {
        return [];
    }

    async generateURLForOffloadingQR(): Promise<PossibleTrackedURL | undefined> {
        return undefined;
    }

    async getBasketId(): Promise<string | undefined> {
        return undefined;
    }
}
