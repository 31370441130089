import { AttributeInput } from '../provider/shopify/graphql/generated/shopify_types';
import { CloudshelfEngineConfig } from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { SessionManagementService } from '../services/SessionManagementService/SessionManagementService';
import { StorageKey } from '../services/StorageService/StorageKeys.enum';
import { StorageService } from '../services/StorageService/StorageService';

export interface OrderExtraDetails {
    attributes: AttributeInput[];
    note: string | null;
}

export class OrderAttributeUtils {
    static async getOrderExtraDetails(
        config: CloudshelfEngineConfig,
        storageService: StorageService,
        sessionService: SessionManagementService,
    ): Promise<OrderExtraDetails> {
        const result: OrderExtraDetails = {
            attributes: [],
            note: null,
        };

        const cloudshelfName: string = config.name ?? 'UNKNOWN';
        let originatingStore: string | null = null;
        let deviceName: string | null = null;
        let salesAssistant = 'UNASSIGNED';
        let sessionId: string | null = null;

        if (config.device) {
            deviceName = config.device.name ?? null;
            if (config.device.location) {
                originatingStore = config.device.location.name ?? null;
            } else {
                originatingStore = 'UNASSIGNED';
            }
        }

        if (config.retailerRules.allocateSalesToAssignedSalesPerson) {
            const storedAssociate = storageService.get(StorageKey.SALES_ASSOCIATE_ID);
            if (storedAssociate) {
                const salesPerson = config.teamMembers.find(member => member.id === storedAssociate);
                salesAssistant = salesPerson ? salesPerson.reportingValue : 'UNASSIGNED';
            }
        }

        if (sessionService.sanitizedSessionId) {
            sessionId = sessionService.sanitizedSessionId;
        }

        result.attributes.push({ key: 'CS_Cloudshelf', value: cloudshelfName });
        result.attributes.push({ key: 'CS_Device', value: deviceName ?? 'N/A - PREVIEW MODE' });
        result.attributes.push({ key: 'CS_OriginatingStore', value: originatingStore ?? 'N/A - PREVIEW MODE' });
        result.attributes.push({ key: 'CS_SalesAssistant', value: salesAssistant });
        result.attributes.push({ key: 'CS_SessionId', value: sessionId ?? 'N/A - PREVIEW MODE' });

        if (config.device?.isCloudshelfInternalDevice) {
            result.note =
                'This purchase was contracted on a Cloudshelf test device, please confirm order with customer';
        }
        return result;
    }
}
