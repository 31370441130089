import { inject, injectable } from 'inversify';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { Category } from '../CategoryService/entities/Category';
import { EmptyDisplayOnlyConfig } from '../ConfigurationService/EmptyConfig';
import { Banner, DisplayOnlyEngineConfig } from '../ConfigurationService/types/config/CloudshelfEngineConfig';
import _ from 'lodash';
import { LogUtil } from '../../utils/Logging.Util';
import { ProductFilteringService } from '../ProductServices/ProductFilteringService';
import PriceService from '../PriceService/PriceService';
import {
    BannerDisplayMode,
    FilterType,
    NonInteractiveCollectionType,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FilterableProductVariant } from '../ProductServices/LocalProduct';
import * as Sentry from '@sentry/react';
import DependencyType from '../../dependancyInjection/DependencyType';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
    buildBuiltInSortOrder_MostRecent,
    buildBuiltInSortOrder_PriceAsc,
    buildBuiltInSortOrder_PriceDesc,
} from '../../utils/EngineFilter.Util';

export interface DisplayState {
    availableBanners: Banner[];
    availableCategories: DisplayOnlyCategory[];
    currentCategoryIndex: number;
    bannerIndex: number;
    shouldShowBanner: boolean;
}

export interface DisplayOnlyProduct {
    handle: string;
    title: string;
    images: string[];
    currentPrices: string;
    previousPrices?: string;
    availableForSale: boolean;
    limitedAvailability: boolean;
    orderOnly: boolean;
    currentProductImageIndex: number;
}

export interface DisplayOnlyCategory {
    title: string;
    handle: string;
    featuredImage?: string;
    products: DisplayOnlyProduct[];
    currentProductIndex: number;
}

@injectable()
export class DisplayOnlyOrchestratorService {
    private configService: ConfigurationService;
    private priceService: PriceService;
    private configServiceObserver: Observable<void>;
    private productFilteringService: ProductFilteringService;
    private _availableBanners: Banner[] = [];
    private _availableCategories: DisplayOnlyCategory[] = [];
    private _displayState: DisplayState = {
        availableBanners: [],
        availableCategories: [],
        currentCategoryIndex: -1,
        bannerIndex: -1,
        shouldShowBanner: false,
    };
    private _currentCategoryIndex = -1;
    private _currentProductIndex = -1;
    private _currentProductImageIndex = -1;
    private _subject: Subject<void> = new Subject();
    private _isConfigured = false;
    private _settings: DisplayOnlyEngineConfig = EmptyDisplayOnlyConfig;
    private _interval?: NodeJS.Timeout;
    private _nextAllowedAt?: Date;
    private _shouldDisplayBanner = false;
    private _awaitingNextDisplayStatePopulation = false;

    constructor(
        private readonly _configurationService: ConfigurationService,
        private readonly _productFilteringService: ProductFilteringService,
        private readonly _priceService: PriceService,
        @inject(DependencyType.ApolloClient)
        private readonly apolloClient: ApolloClient<InMemoryCache>,
    ) {
        this._isConfigured = false;
        this.configService = _configurationService;
        this.productFilteringService = _productFilteringService;
        this.priceService = _priceService;
        this.configServiceObserver = this.configService.observe();
        this.setupQAHelpers();
    }

    setupQAHelpers() {
        (window as any).qaHelper_DisplayOnlyOrchestratorToggle = () => {
            if (this._interval) {
                this.stop();
                console.info('Asked DisplayOnlyOrchestratorService to stop');
            } else {
                this.start();
                console.info('Asked DisplayOnlyOrchestratorService to start');
            }
        };
    }

    canDisplayProducts(): boolean {
        if (this._availableCategories.length === 0) {
            return false;
        }

        //map over all categories and see if any have product with at least one image
        const hasProductWithImage = this._availableCategories.some(category => {
            return category.products.some(product => {
                return product.images.length > 0;
            });
        });

        return hasProductWithImage;
    }

    get displayState(): DisplayState {
        return this._displayState;
    }

    start() {
        if (this._interval) {
            console.info('DisplayOnlyOrchestratorService, start called but already started');
            return;
        }

        if (this._availableCategories.length === 0) {
            console.info('DisplayOnlyOrchestratorService, start called but no categories');
            // this._subject.next(_.cloneDeep(this._displayState));
            this._subject.next();
            return;
        }

        this._interval = setInterval(() => {
            this.handleNext();
        }, 100);
    }

    stop() {
        if (this._interval) {
            clearInterval(this._interval);
        }
        this._interval = undefined;
        this._nextAllowedAt = undefined;
    }

    async populateDisplayState() {
        this.setSettings(this.configService.config()?.displayOnly ?? EmptyDisplayOnlyConfig);
        await this.setCategories(this.configService.categories);
        await this.setBanners(this.configService.config()?.banners ?? []);
        this._subject.next();
    }

    setSettings(settings: DisplayOnlyEngineConfig) {
        this._settings = settings;
        this._isConfigured = true;
    }

    async setBanners(banners: Banner[]) {
        try {
            if (!this._isConfigured) {
                LogUtil.Log('DisplayOnlyOrchestratorService, ignoring setBanners as settings have not been provided');
            }

            //We might want to do some sanity checks here like we do for categories, but for now that hasn't been spec'd out.

            LogUtil.Log('Setting banners to: ' + JSON.stringify(banners));
            this._availableBanners = banners;
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'displayOrchestrator.setBanners',
                },
            });
            console.error('DisplayOnlyOrchestratorService, setBanners', err);
        }
    }

    async setCategories(categories: Category[]) {
        try {
            if (!this._isConfigured) {
                LogUtil.Log(
                    'DisplayOnlyOrchestratorService, ignoring setCategories as settings have not been provided',
                );
            }

            const mappedCategories: DisplayOnlyCategory[] = [];
            for await (const category of categories.filter(c => c.handle !== 'INTERNAL_ALL')) {
                const sortOptions: [keyof FilterableProductVariant, boolean][] = [
                    ['availableForSale', true], //We always want the available for sales one to be displayed as a priority
                ];

                let randomSort = false;
                if (!this.configService.isDevice() || !this.configService.config()?.device?.debugMode) {
                    if (this.configService.displayOnlyConfig.collectionType === NonInteractiveCollectionType.Random) {
                        randomSort = true;
                    } else if (
                        this.configService.displayOnlyConfig.collectionType === NonInteractiveCollectionType.Recent
                    ) {
                        _.map(buildBuiltInSortOrder_MostRecent(), (v, k) => sortOptions.push(v));
                    } else if (
                        this.configService.displayOnlyConfig.collectionType === NonInteractiveCollectionType.Cheapest
                    ) {
                        _.map(buildBuiltInSortOrder_PriceAsc(), (v, k) => sortOptions.push(v));
                    } else if (
                        this.configService.displayOnlyConfig.collectionType ===
                        NonInteractiveCollectionType.MostExpensive
                    ) {
                        _.map(buildBuiltInSortOrder_PriceDesc(), (v, k) => sortOptions.push(v));
                    }
                }

                LogUtil.Log(`Selecting ${this._settings.maxProductsPerCollection} products from ${category.title}`);
                const productsForCategory = await this._productFilteringService.matchingProducts(
                    'DisplayOnlyOrchestrator -> productsForCategory',
                    category,
                    [],
                    { limit: this._settings.maxProductsPerCollection, sort: sortOptions, randomSort },
                    false,
                );
                const filtered = productsForCategory.products.filter(p => p.images.length > 0);
                const totalCount = filtered.length;
                const allTheProductsWoo = {
                    totalCount,
                    products: filtered,
                };

                if (allTheProductsWoo.totalCount > 0) {
                    const mappedCategory: DisplayOnlyCategory = {
                        title: category.title,
                        handle: category.handle,
                        featuredImage: category.image,
                        products: _.compact(
                            _.map(productsForCategory.products, (product: any) => {
                                const currentPrices = this.priceService.getPriceRange(
                                    product.minPrice,
                                    product.maxPrice,
                                );
                                const previousPrices = this.priceService.getPriceRange(
                                    product.minPriceOriginal,
                                    product.maxPriceOriginal,
                                );

                                const hasPreviousPrice =
                                    product.minPriceOriginal !== 0 && product.maxPriceOriginal !== 0;

                                const mappedImages = _.map(product.images, img => img.url);

                                let productImages = [...(mappedImages ?? [])];
                                productImages = _.uniq(productImages);

                                const images: string[] = [];
                                if (productImages.length <= this._settings.maxPhotosPerProduct) {
                                    images.push(...productImages);
                                } else {
                                    images.push(...productImages.slice(0, this._settings.maxPhotosPerProduct));
                                }

                                if (images.length === 0) {
                                    return null;
                                }

                                const tempProduct: DisplayOnlyProduct = {
                                    handle: product.handle,
                                    title: product.title,
                                    images,
                                    currentPrices,
                                    previousPrices: hasPreviousPrice
                                        ? previousPrices === currentPrices
                                            ? undefined
                                            : previousPrices
                                        : undefined,
                                    availableForSale: product.availableForSale,
                                    limitedAvailability: product.limitedAvailability,
                                    orderOnly: product.orderOnly,
                                    currentProductImageIndex: -1,
                                };

                                return tempProduct;
                            }),
                        ),
                        currentProductIndex: 0,
                    };

                    if (mappedCategory.products.length > 0) {
                        mappedCategories.push(mappedCategory);
                    }
                }
            }

            this._availableCategories = mappedCategories;
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'displayOrchestrator.setCategories',
                },
            });
            console.error('DisplayOnlyOrchestratorService, setCategories', err);
        }
    }

    observe(): Observable<void> {
        return this._subject.asObservable();
    }

    async handleNext() {
        try {
            if (this._awaitingNextDisplayStatePopulation) {
                return true;
            }

            if (this._nextAllowedAt && this._nextAllowedAt > new Date()) {
                return;
            }

            if (
                this._currentCategoryIndex === -1 &&
                this._currentProductIndex === -1 &&
                this._currentProductImageIndex === -1
            ) {
                this._currentCategoryIndex = 0;
                this._currentProductIndex = 0;
                this._currentProductImageIndex = -1;
            } else {
                if (this._shouldDisplayBanner) {
                    //We did show a banner, so let's remove it
                    this._shouldDisplayBanner = false;
                } else {
                    const currentCategory = this._availableCategories[this._currentCategoryIndex];
                    const currentProduct = currentCategory.products[this._currentProductIndex];
                    if (currentCategory && currentProduct) {
                        if (currentProduct.currentProductImageIndex < currentProduct.images.length - 1) {
                            this._currentProductImageIndex++;
                        } else {
                            this._currentProductImageIndex = 0;
                            if (this._currentProductIndex < currentCategory.products.length - 1) {
                                this._currentProductIndex++;
                            } else {
                                this._currentProductIndex = 0;
                                if (this._currentCategoryIndex < this._availableCategories.length - 1) {
                                    this._currentCategoryIndex++;
                                    this._currentProductImageIndex = -1;
                                    if (
                                        this.configService.config()?.bannerDisplayRules.display.displayMode ===
                                        BannerDisplayMode.NonInteractiveAfterCollection
                                    ) {
                                        this._shouldDisplayBanner = true;
                                    }
                                } else {
                                    console.log('WEVE SEEN ALL THE CATEGORIES');

                                    //Stops the timer calling it again until its finished.
                                    this._awaitingNextDisplayStatePopulation = true;

                                    if (
                                        this.configService.config()?.bannerDisplayRules.display.displayMode ===
                                        BannerDisplayMode.NonInteractiveAfterLoop
                                    ) {
                                        this._shouldDisplayBanner = true;
                                    }

                                    try {
                                        await this.productFilteringService.updateLokiCache();
                                    } catch (err) {
                                        throw new Error(`Failed to update loki cache: ${err}`);
                                    }

                                    try {
                                        await this.populateDisplayState();
                                    } catch (err) {
                                        throw new Error(`Failed to populate display state: ${err}`);
                                    }

                                    this._currentCategoryIndex = 0;
                                    this._currentProductImageIndex = -1;
                                    //Allows the function to be called again
                                    this._awaitingNextDisplayStatePopulation = false;
                                }
                            }
                        }
                    }
                }
            }

            this._displayState.availableBanners = this._availableBanners;
            this._displayState.availableCategories = this._availableCategories;
            this._displayState.currentCategoryIndex = this._currentCategoryIndex;
            this._displayState.availableCategories[this._currentCategoryIndex].currentProductIndex =
                this._currentProductIndex;
            this._displayState.availableCategories[this._currentCategoryIndex].products[
                this._currentProductIndex
            ].currentProductImageIndex = this._currentProductImageIndex;

            //Handling banners
            if (
                this.configService.config()?.bannerDisplayRules.display.displayMode === BannerDisplayMode.NoBanners ||
                this.configService.config()?.banners.length === 0
            ) {
                this._shouldDisplayBanner = false;
                this._displayState.shouldShowBanner = false;
                this._displayState.bannerIndex = -1;
            } else {
                this._displayState.shouldShowBanner = this._shouldDisplayBanner;
                //If we should be showing a banner, then we want to show the next banner or reset to the first one
                if (this._shouldDisplayBanner) {
                    if (this._displayState.bannerIndex < this._availableBanners.length - 1) {
                        this._displayState.bannerIndex++;
                    } else {
                        this._displayState.bannerIndex = 0;
                    }
                }
            }

            if (this._shouldDisplayBanner) {
                this._nextAllowedAt = new Date(
                    Date.now() + (this.configService.config()?.bannerDisplayRules.display.duration ?? 1) * 1000,
                );
            } else {
                this._nextAllowedAt = new Date(
                    new Date().getTime() + this.configService.displayOnlyConfig.timePerPhoto * 1000,
                );
            }

            if (this._configurationService.config()?.device?.debugMode) {
                await this.reportContentLoad();
            }
            this._subject.next();
        } catch (err) {
            console.log('Error in display only service');
            Sentry.captureException(err, {
                extra: {
                    operationName: 'displayOnlyService.handleNext',
                },
            });
        }
    }

    async reportContentLoad() {
        //TODO Report content load
        // if (
        //     this._configurationService.isDevice() &&
        //     this._configurationService.config()?.device?.id &&
        //     this.displayState !== undefined
        // ) {
        //     try {
        //         const cat = this._displayState.availableCategories[this._currentCategoryIndex];
        //         const prod = cat.products[cat.currentProductIndex];
        //         let image = '';
        //         if (prod.currentProductImageIndex === -1) {
        //             image = 'CATEGORY IMAGE';
        //         } else {
        //             image = prod.images[prod.currentProductImageIndex];
        //         }
        //         await this.apolloClient.mutate<ReportContentLoadMutation, ReportContentLoadMutationVariables>({
        //             mutation: ReportContentLoadDocument,
        //             variables: {
        //                 input: {
        //                     deviceId: this._configurationService.config()?.device?.id ?? '',
        //                     categoryName: cat.title,
        //                     productName: prod.title,
        //                     imageURL: image,
        //                 },
        //             },
        //         });
        //     } catch (e) {
        //         console.error('Error reporting content load', e);
        //     }
        // }
    }
}
